import React from "react";

const Chat: React.FC = () => {
  return (
    <div>
      <h1>Chat Page</h1>
      {/* Your chat-related content here */}
    </div>
  );
};

export default Chat;
