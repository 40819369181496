import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:5000/api";
const POLL_INTERVAL = 15000; // Poll interval in milliseconds

// Fetch Figma file by file ID
export const fetchFigmaFile = async (fileId: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/figma/file/${fileId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching Figma file:", error);
    throw error;
  }
};

// Start RAG creation job and return tracking ID
export const createPDFRag = async (
  token: string,
  orgID: string,
  mode: string
): Promise<string | null> => {
  try {
    const response = await axios.post(`${BASE_URL}/createRAG`, { orgID, mode },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const { trackingId } = response.data;
    return trackingId; // Return trackingId for polling
    //return response.data;
  } catch (error) {
    console.error("Error starting RAG creation job:", error);
    return null;
  }
};

// Check the job status for a given tracking ID
export const checkJobStatus = async (token: string, trackingId: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/check-status/${trackingId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data; // Returns job status and result if completed
  } catch (error) {
    console.error("Error checking job status:", error);
    throw error;
  }
};

// Poll job status until it completes or fails
export const pollJobStatus = async (
  token: string,
  trackingId: string,
  onComplete: (result: any) => void,
  onError: (error: Error) => void
): Promise<void> => {
  const poll = setInterval(async () => {
    try {
      const statusData = await checkJobStatus(token, trackingId);

      console.log("Job status:", statusData.status);

      // Check if the job is completed
      if (statusData.status === "COMPLETED") {
        clearInterval(poll);
        onComplete(statusData.result); // Call onComplete with the final result
      } else if (statusData.status === "FAILED") {
        clearInterval(poll);
        onError(new Error("Batch job failed"));
      }
    } catch (error) {
      clearInterval(poll);
      onError(error as Error); // Cast error to Error type for compatibility
    }
  }, POLL_INTERVAL);
};

export const fetchFigmaImage = async (fileId: string, nodeId: string) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/figma/image/${fileId}/${nodeId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Figma file:", error);
    throw error;
  }
};

export const getAuthToken = async (username: string, password: string) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/get-token`, {
      username,
      password,
    });
    const token = response.data.access_token;

    // Store the token in localStorage (or context for a more secure approach)
    if (token) {
      localStorage.setItem("authToken", token);
    }

    return token;
  } catch (error) {
    console.error("Error fetching token:", error);
    return null;
  }
};

export const uploadPDF = async (token: string, formData: FormData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/upload`,
      formData, // Pass formData directly as the second argument
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data", // Important for form data
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error uploading PDF:", error);
    return null;
  }
};
