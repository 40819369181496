import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import React from "react";
interface ContentDetail {
  title: string;
  info: string;
  name?: string;
  colours?: Colour[];
  image?: string; // Add an optional image property for logo images
}
interface Colour {
  Palette: string;
  Usage: string;
  Name: string;
  Codes: string[]; // Empty or filled, depending on your use case
  Hex: string; // Can be an empty string or a valid hex value
}

interface DetailGridProps {
  isOpen: boolean;
  content: { name: string; type: string; description?: ContentDetail[] } | null; // Add type field
  onClose: () => void;
}

const DetailGrid: React.FC<DetailGridProps> = ({
  isOpen,
  content,
  onClose,
}) => {

  if (!isOpen || !content) return null;
  const renderColorBoxes = (colour: any) => {
    if (colour.Hex !== "") {
      return (
        <div className="flex flex-col gap-2">
          <h1 className="text-slate-700 font-bold text-lg mt-1 flex flex-row gap-2">
            {colour.Name}
            <button
              onClick={() => {
                navigator.clipboard.writeText(colour.Hex);
              }}
            >
              <ContentCopyIcon className="text-slate-400 pb-1" fontSize="small" />
            </button>
          </h1>
          <div
            style={{ backgroundColor: colour.Hex }}
            className="w-full h-24 rounded-md mx-auto"
          />
          {colour.Usage && colour.Usage.length < 100 &&
            <div className="flex flex-row gap-2">
              <p className="text-slate-500 font-medium text-sm">{colour.Usage}</p>
            </div>
          }
          <div className="flex flex-row gap-2 items-center">
            <div className="text-slate-400 text-sm">HEX</div>
            <div className="text-slate-700 text-sm">{colour.Hex}</div>
          </div>
          {/* {colour.Codes.length > 0 && (
            <ul className="text-xs">
              {colour.Codes.map(
                (
                  code:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<
                        any,
                        string | React.JSXElementConstructor<any>
                      >
                    | Iterable<React.ReactNode>
                    | React.ReactPortal
                    | null
                    | undefined,
                  codeIndex: React.Key | null | undefined
                ) => (
                  <li key={codeIndex}>{code}</li>
                )
              )}
            </ul>
          )} */}
        </div>
      );
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-gray-200 rounded-lg p-4 w-full max-h-[90vh] max-w-4xl overflow-y-auto relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500"
        >
          ✕
        </button>
        <h3 className="text-lg font-semibold mb-4">{content.name}</h3>

        {/* Conditionally render grid or description */}
        {content.name === "logo" ? (
          <div className="grid grid-cols-3 gap-4">
            {content.description?.map((detail, index) => (
              <div key={index} className="p-2">
                <div className="rounded-lg col-span-2 col-span-1 bg-white flex flex-col relative p-4">
                  {/* <h2 className="text-gray-800 mb-2">Coverpage</h2>{" "} */}
                  <div className="text-sm text-gray-500">
                    {content.name}
                  </div>{" "}
                  <p className="font-semibold text-sm mb-2 text-wrap break-words">{detail.name}</p>
                  {/* Dark grey color */}
                  <div
                    className="bg-[#F0F4F8] p-2 rounded-lg flex-1 flex flex-col items-center justify-center"
                    style={{ height: "90%" }}
                  >
                    {detail.image ? (
                      <img
                        src={detail.image}
                        alt={detail.title}
                        className="object-contain w-full h-full"
                      />
                    ) : (
                      <p className="text-gray-500">No Image</p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : content.name === "colours" ? (
          <div className="grid grid-cols-3 gap-6">
            {/* 5 columns for colors */}
            {content.description?.filter((detail: any) => detail.Hex)?.map((detail, index) => (
              <div key={index} className="">
                <div className="rounded-lg col-span-1 bg-white flex flex-col relative p-4">
                  <h2 className="text-slate-500 font-bold uppercase text-[12px]">Colours</h2>{" "}
                  {/* Dark grey color */}
                  {/* <div
                    className="bg-[#F0F4F8] p-2 rounded-lg flex-1 flex flex-col items-center justify-center"
                    style={{ height: "90%" }}
                  > */}
                  {renderColorBoxes(detail)}
                  {/* </div> */}
                </div>
              </div>
            ))}
          </div>
        ) : content.name === "coverpage" ? (
          <div className="grid grid-cols-3 gap-4">
            {/* 5 columns for cover images */}
            {content.description?.map((detail, index) => (
              <div key={index} className="p-2">
                <div className="rounded-lg col-span-2 col-span-1 bg-white flex flex-col relative p-4">
                  {/* <h2 className="text-gray-800 mb-2">Coverpage</h2>{" "} */}
                  <div className="text-sm text-gray-500">
                    {content.name}
                  </div>{" "}
                  <p className="font-semibold text-sm mb-2 text-wrap break-words">{detail.name}</p>
                  {/* Dark grey color */}
                  <div
                    className="bg-[#F0F4F8] p-2 rounded-lg flex-1 flex flex-col items-center justify-center"
                    style={{ height: "90%" }}
                  >
                    {detail.image ? (
                      <img
                        src={detail.image}
                        alt={detail.title}
                        className="object-contain w-full h-full"
                      />
                    ) : (
                      <p className="text-gray-500">No Image</p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : content.name === "iconography" ? (
          <div className="grid grid-cols-3 gap-4">
            {/* 5 columns for fonts */}
            {content.description?.map((detail, index) => (
              <div key={index} className="p-2">
                <div className="rounded-lg col-span-2 col-span-1 bg-white flex flex-col relative p-4">
                  {/* <h2 className="text-gray-800 mb-2">Coverpage</h2>{" "} */}
                  <div className="text-sm text-gray-500">
                    {content.name}
                  </div>{" "}
                  <p className="font-semibold text-sm mb-2 text-wrap break-words">{detail.name}</p>
                  {/* Dark grey color */}
                  <div
                    className="bg-[#F0F4F8] p-2 rounded-lg flex-1 flex flex-col items-center justify-center"
                    style={{ height: "90%" }}
                  >
                    {detail.image ? (
                      <img
                        src={detail.image}
                        alt={detail.title}
                        className="object-contain w-full h-full"
                      />
                    ) : (
                      <p className="text-gray-500">No Image</p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <>
            {/* Canvas */}
            <canvas
              id="descriptionCanvas"
              width="400"
              height="300"
              className="bg-gray-100 mb-4"
            />

            {/* Description Array */}
            <div className="text-gray-700">
              {content.description?.map((detail, index) => (
                <div key={index} className="mb-2">
                  <h4 className="font-semibold">{detail.title}</h4>
                  <p>{detail.info}</p>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DetailGrid;
