// src/components/Bento.tsx
import React, { useState } from "react";
import DetailGrid from "./DetailGrid";
import { IconButton } from "@mui/material";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

interface ContentDetail {
  title: string;
  info: string;
  annotation: [];
}

interface ContentItem {
  name: string;
  description?: ContentDetail[]; // Now an array of objects
  url?: string;
  hexcode?: string;
  type: string;
}

interface BentoBox {
  type: string;
  contents: ContentItem[];
}

interface BentoProps {
  data: BentoBox[];
}

const Bento: React.FC<BentoProps> = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<ContentItem | null>(null);

  const contentMap: { [key: string]: ContentItem[] } = {};
  data.forEach((box) => {
    contentMap[box.type] = box.contents;
  });

  const openModal = (content: ContentItem) => {
    const details = transformContent(content);
    setModalContent(details);
    setIsModalOpen(true);
  };
  const transformContent = (content: any) => {
    const result: any = [];
    content.description.forEach((details: any) => {
      try {
        // Parse the annotation string to JSON
        const annotation = details.annotation;

        // Iterate through the colours array inside the annotation
        if (content.name === "colours") {
          annotation?.Colours?.forEach((colour: any) => {
            // Push the formatted object to the result array
            result.push({
              Palette: colour.Palette || "",
              Usage: colour.Usage || "",
              Name: colour.Name || "",
              Codes: colour.Codes || [],
              Hex: colour.Hex || "",
            });
          });
        } else if (content.name === "logo") {
          details.name = details.file_key.split("/").pop().replace(".png", "");
          result.push(details);
          //content.description = result;
        } else if (content.name === "iconography") {
          details.name = details.file_key.split("/").pop().replace(".png", "");
          result.push(details);
          //content.description = result;
        } else if (content.name === "coverpage") {
          details.name = details.file_key.split("/").pop().replace(".png", "");
          result.push(details);
          //content.description = result;
        }
      } catch (error) {
        console.error("Error parsing annotation:", error);
      }
    });
    if (result?.length) {
      content.description = result;
    }
    return content;
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  // Create a mapping of type to contents for easier access
  // const contentMap: { [key: string]: ContentItem[] } = {};
  // data.forEach((box) => {
  //   contentMap[box.type] = box.contents;
  // });

  return (
    <div className="grid h-[90vh] grid-cols-3 gap-4 grid-rows-1 w-[67vw]">
      <div className="grid col-span-2 grid-cols-1 gap-4 grid-rows-3">
        <div className="grid grid-cols-3 grid-rows-1 gap-4">
          <div className="rounded-lg col-span-2 bg-white flex flex-col p-4 relative">
            <h2 className="text-slate-500 uppercase text-[12px] font-bold mb-2">Coverpage</h2>{" "}
            {contentMap["coverpage"]?.[0] && (
              <div className="absolute w-8 h-8 top-2 right-2">
                <IconButton
                  className="w-8 h-8 text-gray-500"
                  onClick={() => openModal(contentMap["coverpage"]?.[0])}
                >
                  <ArrowOutwardIcon />
                </IconButton>
              </div>
            )}
            <div
              className="bg-[#F0F4F8] rounded-lg flex-1 flex flex-col overflow-hidden"
            >
              {contentMap["coverpage"]?.map((content, index) => (
                <>
                  <img
                    key={'coverpage' + index}
                    src={content.url}
                    alt={content.name}
                    className="object-cover w-full h-full"
                  />
                </>
              ))}
            </div>
          </div>
          <div className="rounded-lg flex flex-col bg-white relative p-4 flex-1">
            <h2 className="text-slate-500 uppercase text-[12px] font-bold mb-2">Logos</h2>
            {contentMap["logo"]?.[0] && (
              <div className="absolute w-8 h-8 top-2 right-2">
                <IconButton
                  className="w-8 h-8 text-gray-500"
                  onClick={() => openModal(contentMap["logo"]?.[0])}
                >
                  <ArrowOutwardIcon />
                </IconButton>
              </div>
            )}
            <div className="bg-[#F0F4F8] rounded-lg flex flex-1 flex-col overflow-hidden">
              {contentMap["logo"]?.map((content, index) => (
                <img
                  key={'logo' + index}
                  src={content.url}
                  alt={content.name}
                  className="object-cover w-full h-full"
                />
              ))}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 grid-rows-1 gap-4">
          <div className="rounded-lg col-span-1 bg-white relative p-4 flex flex-col">
            <h2 className="text-slate-500 uppercase text-[12px] font-bold mb-2">Font</h2>{" "}
            {contentMap["font"]?.[0] && (
              <div className="absolute w-8 h-8 top-2 right-2">
                <IconButton
                  className="w-8 h-8 text-gray-500"
                  onClick={() => openModal(contentMap["font"]?.[0])}
                >
                  <ArrowOutwardIcon />
                </IconButton>
              </div>
            )}
            <div
              className="bg-[#F0F4F8] rounded-lg flex flex-col items-center justify-center flex-1 overflow-hidden"
            >
              {contentMap["font"]?.map((content, index) => (
                <img
                  key={'font' + index}
                  src={content.url}
                  alt={content.name}
                  className="object-cover w-full h-full"
                />
              ))}
            </div>
          </div>
          <div className="rounded-lg col-span-2 flex flex-col bg-white relative p-4">
            <h2 className="text-slate-500 uppercase text-[12px] font-bold mb-2">Iconography</h2>
            {contentMap["iconography"]?.[0] && (
              <div className="absolute w-8 h-8 top-2 right-2">
                <IconButton
                  className="w-8 h-8 text-gray-500"
                  onClick={() => openModal(contentMap["iconography"]?.[0])}
                >
                  <ArrowOutwardIcon />
                </IconButton>
              </div>
            )}
            <div
              className="bg-[#F0F4F8] rounded-lg flex flex-col items-center justify-center flex-1 overflow-hidden"
            >
              {contentMap["iconography"]?.map((content, index) => (
                <img
                  key={'iconography' + index}
                  src={content.url}
                  alt={content.name}
                  className="object-cover w-full h-full"
                />
              ))}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 grid-rows-1 gap-4">
          <div className="rounded-lg col-span-2 bg-white relative p-4 flex flex-col">
            <h2 className="text-slate-500 uppercase text-[12px] font-bold mb-2">Photography</h2>{" "}
            <div
              className="bg-[#F0F4F8] p-2 rounded-lg flex-1"
            ></div>
          </div>
          <div className="rounded-lg col-span-1 bg-white relative p-4 flex flex-col">
            <h2 className="text-slate-500 uppercase text-[12px] font-bold mb-2">Motion Graphics</h2>{" "}
            <div
              className="bg-[#F0F4F8] p-2 rounded-lg flex-1"
            ></div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="rounded-lg flex flex-col flex-1 bg-white relative p-4">
          <h2 className="text-slate-500 uppercase text-[12px] font-bold mb-2">Colors</h2>
          {contentMap["color"]?.[0] && (
            <div className="absolute w-8 h-8 top-2 right-2">
              <IconButton
                className="w-8 h-8 text-gray-500"
                onClick={() => openModal(contentMap["color"]?.[0])}
              >
                <ArrowOutwardIcon />
              </IconButton>
            </div>
          )}
          <div
            className="bg-[#F0F4F8] rounded-lg flex flex-1 flex-row justify-between overflow-hidden"
            style={{ height: "80%" }}
          >
            {contentMap["color"]?.map((content, index) => (
              <img
                key={'color' + index}
                src={content.url}
                alt={content.name}
                className="object-cover w-full h-full"
              />
            ))}
          </div>
        </div>
        <div className="rounded-lg col-span-2 flex flex-col row-span-2 bg-white relative p-4 flex-1">
          <h2 className="text-slate-500 uppercase text-[12px] font-bold mb-2">Typography</h2>
          <div
            className="bg-[#F0F4F8] p-2 rounded-lg"
            style={{ height: "90%" }}
          ></div>
        </div>
      </div>
      <DetailGrid
        isOpen={isModalOpen}
        content={modalContent}
        onClose={closeModal}
      />
    </div>
  );
};

export default Bento;
