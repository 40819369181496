import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Box, Fab, Grow, IconButton } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import LinkIcon from "@mui/icons-material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import { ZoomControls } from "./ZoomControls";
import { Sidebar } from "./Sidebar";
import { UserMenu } from "./UserMenu";
import FigmaViewer from "./FigmaViewer";
import { ChatBoxRef, ChatBox } from "./ChatBox";
import Bento from "./Bento";
import { extractTokenFromUrl, storeToken } from "../utils/auth";
import {
  getAuthToken,
  uploadPDF,
  createPDFRag,
  pollJobStatus,
  checkJobStatus,
} from "../utils/api";
import { ChatContext } from "../App";

const Agent: React.FC = () => {
  const { setMessages } = useContext(ChatContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const chatBoxRef = useRef<ChatBoxRef | null>(null);
  const [expandIcons, setExpandIcons] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [scale, setScale] = useState(1);
  const [pdfData, setPdfData] = useState<any[]>([]); // Modify as needed for your `PdfData` structure
  const [isPDFUploaded, setIsPDFUploaded] = useState(false);
  const [isFirstRun, setIsFirstRun] = useState(true);
  const [token, setToken] = useState<string>("");
  const [trackingId, setTrackingId] = useState<string | null>(null); // Track the RAG job
  const [pollingError, setPollingError] = useState<string | null>(null);
  const canvasRef = useRef<HTMLDivElement | null>(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

  const toggleSidebar = () => setIsSidebarOpen((prev) => !prev);

  const handleFileUpload = (file: File) => {
    // toggleSidebar();
    chatBoxRef.current?.handleFileDrop(file);
  };
  // Handle Drag Start
  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    setStartPosition({ x: e.clientX - position.x, y: e.clientY - position.y });
  };

  // Handle Drag End
  const handleMouseUp = () => setIsDragging(false);
  // interface LinkContent {
  //   name: string;
  //   url: string;
  // }

  // interface ColorContent {
  //   name: string;
  //   type: string;
  //   hexcode: string;
  //   rgb: string;
  // }

  // interface GenericContent {
  //   name: string;
  //   type: string;
  // }
  // type PdfData = {
  //   type: string;
  //   contents: LinkContent[] | ColorContent[] | GenericContent[];
  // }[];

  interface ContentResponse {
    layer0: {
      image: string;
    };
    layer1: object;
  }

  interface InnerResponseType {
    [key: string]: {
      response: ContentResponse;
    };
  }

  // Handle Drag Move
  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging) return;
    setPosition({
      x: e.clientX - startPosition.x,
      y: e.clientY - startPosition.y,
    });
  };

  const firstRunComplete = () => {
    setIsFirstRun(false);
    setIsSidebarOpen(true);
    chatBoxRef.current?.isFreshStart(false);
  };
  const uploadPdf = async (file: File) => {
    console.log("token is", token);
    const formData = new FormData();
    // create new namespace everytime uploading a pdf
    formData.append("target_path", `test/pdf/${file.name}`);
    formData.append("file", file);
    formData.append("bucket_name", "lumina-content-upload");
    const uploadStatus = await uploadPDF(token, formData);
    if (uploadStatus) {
      // start showing the loader
      //set data in chatbox and sidebar and start showing sidebar with initial messages
      //setIsPDFUploaded(true);
      //toggleSidebar();
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: uploadStatus.message, sender: "bot" },
      ]);
      //toggleSidebar();
      createRagForPDF();
    } else {
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: "Failed to upload PDF", sender: "bot" },
      ]);
    }
  };

  const transformPDFData = (PDFDetails: any) => {
    //toggleSidebar();
    console.log("PDFDetails", PDFDetails);
    const transformedData = transformData(PDFDetails.result);
    setPdfData(transformedData);
  };

  const createRagForPDF = async () => {
    const trackingId = await createPDFRag(token, "demo", "PDF"); // Create RAG job
    if (trackingId) {
      setTrackingId(trackingId); // Store tracking ID to start polling
    } else {
      setPollingError("Failed to start RAG creation job.");
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setExpandIcons(!expandIcons);
    if (file && file.type === "application/pdf") {
      handleFileUpload(file);
      event.target.value = "";
    }
  };

  // Helper function to transform the response data into BentoBox format
  const transformData = (responseData: any) => {
    // Example transformation based on assumed response structure
    const logoContents = [];
    const colorContents = [];
    const iconographyContents = [];
    const coverpageContents = [];
    const otherContents = [];

    for (const [key, value] of Object.entries(
      responseData as InnerResponseType
    )) {
      if (value.response) {
        const content = {
          name: key,
          url: value.response.layer0.image,
          description: value.response.layer1,
        };
  
        switch (key) {
          case "colours":
            colorContents.push(content);
            break;
          case "coverpage":
            coverpageContents.push(content);
            break;
          case "logo":
            logoContents.push(content);
            break;
          case "iconography":
            iconographyContents.push(content);
            break;
          default:
            otherContents.push(content);
            break;
        }
      }
    }

    return [
      { type: "logo", contents: logoContents },
      { type: "color", contents: colorContents },
      { type: "iconography", contents: iconographyContents },
      { type: "coverpage", contents: coverpageContents },
      { type: "other", contents: otherContents },
    ];
  };

  return (
    <Box display="flex" height="100vh">
      <input
        accept="application/pdf"
        type="file"
        id="fileInput"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileSelect}
      />

      {/* Sidebar */}
      {isSidebarOpen && (
        <Sidebar
          chatBoxRef={chatBoxRef}
          onClose={toggleSidebar}
          setPDFData={transformPDFData}
          firstRunComplete={firstRunComplete}
        />
      )}
      {!isSidebarOpen && !isFirstRun && (
        <button
          className="absolute top-5 left-5 hover:cursor-pointer hover:bg-slate-100 rounded-full rounded-tl-none p-1 active:bg-slate-200"
          onClick={toggleSidebar}
        >
          <div className="bg-[#304FFE] text-white h-10 w-10 rounded-full rounded-tl-none flex justify-center items-center text-2xl font-medium select-none">
            K
          </div>
        </button>
      )}

      {/* Main Content Area */}
      <Box
        width={isSidebarOpen ? "75%" : "100%"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        //padding={2}
        sx={{ marginTop: 8, overflow: "hidden" }}
      >
        {/* Display ChatBox centered if no PDF, else show main content */}
        {isFirstRun ? (
          <Box width="100%" maxWidth="600px">
            <ChatBox
              ref={chatBoxRef}
              //uploadPdf={uploadPdf}
              setPDFData={transformPDFData}
              // isInSideBar={true}
              firstRunComplete={firstRunComplete}
            />
          </Box>
        ) : (
          <Box
            width="100%"
            height="100%"
            position="relative"
            sx={{ overflow: "hidden" }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
          >
            {/* Canvas-like View */}
            <Box
              ref={canvasRef}
              sx={{
                position: "absolute",
                left: position.x,
                top: position.y,
                transform: `scale(${scale})`,
                transition: isDragging ? "none" : "transform 0.2s",
                cursor: isDragging ? "grabbing" : "grab",
              }}
            >
              <div className="bg-[#e2e8f0] p-4 rounded-lg shadow-lg">
                <Bento data={pdfData} />{" "}
              </div>
            </Box>
          </Box>
        )}
      </Box>

      {/* User Menu */}
      <UserMenu />

      {/* Toggle Sidebar Button */}
      {!isSidebarOpen && isPDFUploaded && (
        <IconButton
          onClick={toggleSidebar}
          sx={{ position: "absolute", top: 16, left: 16 }}
        >
          <MenuIcon />
        </IconButton>
      )}

      {/* Floating Action Button */}
      {/* Expanding Plus Button */}
      <Box
        sx={{
          position: "fixed",
          top: 8,
          right: expandIcons ? 100 : -40,
        }}
      >
        <Fab
          color="primary"
          onClick={() => setExpandIcons(!expandIcons)}
          sx={{
            marginRight: 1,
            width: 40, // Set width to 40px
            height: 40, // Set height to 40px
          }}
        >
          <AddIcon />
        </Fab>
        <Grow in={expandIcons} style={{ transformOrigin: "0 50%" }}>
          <IconButton onClick={() => fileInputRef.current?.click()}>
            <UploadIcon />
          </IconButton>
        </Grow>
        <Grow in={expandIcons} style={{ transformOrigin: "0 50%" }}>
          <IconButton>
            <DownloadIcon />
          </IconButton>
        </Grow>
        <Grow in={expandIcons} style={{ transformOrigin: "0 50%" }}>
          <IconButton>
            <LinkIcon />
          </IconButton>
        </Grow>
      </Box>

      {/* Zoom Controls */}
      {<ZoomControls scale={scale} setScale={setScale} />}
    </Box>
  );
};

export default Agent;
