// src/components/ZoomControls.tsx
import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

interface ZoomControlsProps {
  scale: number;
  setScale: React.Dispatch<React.SetStateAction<number>>;
}

export const ZoomControls: React.FC<ZoomControlsProps> = ({
  scale,
  setScale,
}) => {
  const zoomIn = () => setScale((prevScale) => Math.min(prevScale + 0.1, 2));
  const zoomOut = () => setScale((prevScale) => Math.max(prevScale - 0.1, 0.1));
  const zoomPercentage = Math.round(scale * 100); // Calculate the zoom percentage

  return (
    <Box
      position="fixed"
      bottom={16}
      right={16}
      zIndex={1300}
      display="flex"
      alignItems="center"
    >
      <IconButton onClick={zoomOut} color="primary">
        <ZoomOutIcon />
      </IconButton>
      <Typography variant="body1" sx={{ mx: 1 }}>
        {zoomPercentage}%
      </Typography>
      <IconButton onClick={zoomIn} color="primary">
        <ZoomInIcon />
      </IconButton>
    </Box>
  );
};
