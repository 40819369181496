// src/components/UserMenu.tsx
import React, { useState } from "react";
import { IconButton, Avatar, Menu, MenuItem } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { clearToken } from "../utils/auth";

export const UserMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    clearToken(); // Clear the token
    window.location.href =
    process.env.REACT_APP_cognitoLoginUrl || "default_value";
  };

  return (
    <>
      <IconButton
        onClick={handleMenuClick}
        sx={{ position: "fixed", right: 16, zIndex: 1300 }}
      >
        <Avatar src="https://via.placeholder.com/150" alt="User" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleLogout}>
          <LogoutIcon sx={{ marginRight: 1 }} />
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};
