import React, { useEffect, useState, ReactNode, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { getStoredToken, isTokenExpired, initializeToken } from '../utils/auth';

interface ProtectedRouteProps {
  children: ReactNode;
}

const cognitoLoginUrl: string =
    process.env.REACT_APP_cognitoLoginUrl || "default_value";

const ProtectedRoute = ({ children }: ProtectedRouteProps): ReactElement | null => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Attempt to retrieve token from storage or URL
    const token = getStoredToken() || initializeToken();
    console.log('Token found:', token);

    if (token && !isTokenExpired(token)) {
      console.log('Token is valid, setting isAuthenticated to true');
      setIsAuthenticated(true);
    } else {
      console.log('No valid token, redirecting to login');
      // Redirect to the Cognito login page if token is invalid or missing
      window.location.href = cognitoLoginUrl;
    }
  }, []);

  return isAuthenticated ? <>{children}</> : null;
};

export default ProtectedRoute;
