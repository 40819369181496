import React from "react";

const Home: React.FC = () => {
  const cognitoLoginUrl: string =
    process.env.REACT_APP_cognitoLoginUrl || "default_value";
  // Redirect the user to the Cognito login page
  window.location.href = cognitoLoginUrl;
  return null; // This component doesn't render anything
};
export default Home;
