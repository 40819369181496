import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
  useContext,
} from "react";
import {
  Box,
  Paper,
  IconButton,
  Typography,
  InputAdornment,
  TextField,
  Autocomplete,
  Button,
  Backdrop,
  Avatar,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { grey } from "@mui/material/colors";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import * as pdfjsLib from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.entry";
import CloseIcon from "@mui/icons-material/Close";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import HelpIcon from "@mui/icons-material/Help";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { extractTokenFromUrl, storeToken } from "../utils/auth";
import {
  checkJobStatus,
  createPDFRag,
  pollJobStatus,
  uploadPDF,
  getAuthToken,
} from "../utils/api";
import { ChatContext, Message } from "../App";

interface ChatBoxProps {
  //uploadPdf: (file: File) => void;
  setPDFData: (pdfData: PdfData) => void;
  firstRunComplete: () => void;
  isInSideBar?: boolean;
}
interface LinkContent {
  name: string;
  url: string;
}

interface ColorContent {
  name: string;
  type: string;
  hexcode: string;
  rgb: string;
}

interface GenericContent {
  name: string;
  type: string;
}
type PdfData = {
  type: string;
  contents: LinkContent[] | ColorContent[] | GenericContent[];
}[];

export interface ChatBoxRef {
  handleFileDrop: (file: File) => void;
  isFreshStart: (isFreshStart: Boolean) => void;
}

export const ChatBox = forwardRef<ChatBoxRef, ChatBoxProps>(
  ({ setPDFData, firstRunComplete, isInSideBar }, ref) => {
    const {
      messages,
      setMessages,
    } =
      useContext(ChatContext);
    const BASE_URL =
      process.env.REACT_APP_BASE_URL || "http://localhost:5000/api";
    const [userInput, setUserInput] = useState("");
    const textFieldRef = useRef<HTMLInputElement | null>(null);
    const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);
    const [suggestionText, setSuggestionText] = useState<string | null>(null);
    const [isSendDisabled, setIsSendDisabled] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(true);
    const [pollingError, setPollingError] = useState<string | null>(null);
    const [trackingId, setTrackingId] = useState<string>("");
    const [dragOver, setDragOver] = useState(false);
    const [isFirstRun, setIsFirstRun] = useState(true);
    const [fileContent, setFileContent] = useState<File | null>(null);
    const [token, setToken] = useState<string>("");

    const suggestionOptions = [
      "Digitise brand guidelines",
      "Create an animation",
      "Get inspirations",
    ];
    const isFreshStart = () => {
      setIsFirstRun(false);
    };

    // Start polling when trackingId changes
    useEffect(() => {
      const urlToken = extractTokenFromUrl();
      if (urlToken) {
        setToken(urlToken);
        storeToken(urlToken);
      }
    }, []);

    const createRagForPDF = async () => {
      const trackingId = await createPDFRag(token, "demo", "PDF"); // Create RAG job
      if (trackingId) {
        localStorage.setItem("trackingID", trackingId);
        pollJobStatus(token, trackingId, handleJobComplete, handlePollingError);
      }
      addMessage(
        "Great, I am analyzing your file. It will take few minutes",
        "bot"
      );
      // start showing loader
      if (trackingId) {
        setTrackingId(trackingId); // Store tracking ID to start polling
      } else {
        setPollingError("Failed to start RAG creation job.");
      }
    };

    const handlePollingError = (error: Error) => {
      setPollingError(error.message);
      addMessage("Error in job processing", "bot");
      setIsSendDisabled(false);
    };

    // Handle job completion
    const handleJobComplete = (result: any) => {
      setTimeout(() => getPDFDataFromML(), 120000);
      addMessage("RAG job completed successfully", "bot");
      setIsSendDisabled(false);
    };

    const getPDFDataFromML = async () => {
      let id = localStorage.getItem("trackingID");
      if (id) {
        const pdfdata = await checkJobStatus(token, id);
        setPDFData(pdfdata);
        localStorage.setItem("trackingID", "");
        console.log("pdf data is here", pdfdata);
      }
    };

    useEffect(() => {
      if (textFieldRef.current) {
        textFieldRef.current.focus();
      }
    }, [suggestionText]);

    // Expose handleFileDrop to the ref
    useImperativeHandle(ref, () => ({
      handleFileDrop,
      isFreshStart,
    }));

    const handleSubmit = (event: React.FormEvent) => {
      firstRunComplete();
      setIsFirstRun(false);
      event.preventDefault();
      if (thumbnailUrl && fileContent) {
        //if (suggestionText && thumbnailUrl && fileContent) {
        setIsSendDisabled(true);
        uploadPdfFile(fileContent);
        setSuggestionText("");
        setThumbnailUrl("");
      } else if (userInput) {
        addMessage(userInput, "user");
        setUserInput("");
      } else {
        return;
      }
    };

    // Handler for clicking suggestion buttons
    const handleSuggestionClick = (option: number) => {
      let suggestion = "";
      switch (option) {
        case 1:
          // You can add any custom logic to add "upload file" to a message object
          addMessage("Upload any file from PDF to Figma", "bot");
          suggestion = "Digitise brand guidelines";
          break;
        case 2:
          addMessage("Upload image to create an animation", "bot");
          suggestion = "Create an animation";
          break;
        case 3:
          addMessage("Explain use case to get inspirations", "bot");
          suggestion = "Get inspirations";
          break;
        default:
          addMessage("Upload any file from PDF to Figma", "bot");
          setSuggestionText(`Digitise brand guidelines`);
      }
      setSuggestionText(suggestion);
      setShowSuggestions(false);
    };

    const handleFileUpload = async (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const file = event.target.files?.[0];
      if (file && file.type === "application/pdf") {
        const thumbnailUrl = await generatePdfThumbnail(file);
        setThumbnailUrl(thumbnailUrl);
        //save file here and then call on click of
        setFileContent(file);
        addMessage("", "user", file);
        event.target.value = "";
      }
    };

    const uploadPdfFile = async (file: File) => {
      const formData = new FormData();
      formData.append("target_path", "demo/pdf");
      formData.append("file", file);
      formData.append("bucket_name", "lumina-content-upload");
      const uploadStatus = await uploadPDF(token, formData);
      if (uploadStatus) {
        const thumbnailUrl = await generatePdfThumbnail(file);
        setThumbnailUrl(thumbnailUrl);
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: "", sender: "user", file, thumbnailUrl },
        ]);
        addMessage(uploadStatus.message, "bot");
        setThumbnailUrl("");
        createRagForPDF();
      } else {
        addMessage("Failed to upload PDF", "bot");
      }
    };

    const removeThumbnail = () => {
      setThumbnailUrl(null);
      setUserInput("");
    };
    const removeSuggestionText = () => {
      setSuggestionText(null);
      setIsSendDisabled(false);
      setShowSuggestions(true);
    };

    const handleFileDrop = async (file: File) => {
      if (file && file.type === "application/pdf") {
        // uploadPdfFile(file);
        setDragOver(false);
        const thumbnailUrl = await generatePdfThumbnail(file);
        setThumbnailUrl(thumbnailUrl);
        setFileContent(file);
      }
    };

    const generatePdfThumbnail = async (file: File): Promise<string> => {
      const pdfData = await file.arrayBuffer();
      const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;
      const page = await pdf.getPage(1);
      const scale = 0.5;
      const viewport = page.getViewport({ scale });

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      if (!context) {
        throw new Error("Failed to get 2D context for canvas");
      }

      canvas.width = viewport.width;
      canvas.height = viewport.height;

      await page.render({ canvasContext: context, viewport }).promise;

      return canvas.toDataURL();
    };

    const addMessage = (text: string, sender: "bot" | "user", file?: File) => {
      setMessages((prevMessages) => [...prevMessages, { text, sender, file }]);
    };

    // Add drag-and-drop event handlers
    const handleDragOver = (event: React.DragEvent) => {
      event.preventDefault(); // Prevent the default behavior to allow dropping
      setDragOver(true);
    };
    const handleDragLeave = () => {
      setDragOver(false);
    };

    const handleDrop = (event: React.DragEvent) => {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      if (file && file.type === "application/pdf") {
        handleFileDrop(file); // Call handleFileDrop with the dropped file
      }
    };

    const handleAutocompleteChange = (event: any, newValue: string | null) => {
      if (newValue) {
        const index = suggestionOptions.indexOf(newValue) + 1;
        handleSuggestionClick(index);
      }
    };

    const renderMessage = (msg: Message, index: number) => {
      if (msg.sender === "user") {
        return (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
            className="flex gap-2 bg-slate-50 rounded-xl px-4 py-2 rounded-br-none self-end text-right"
          >
            {msg.thumbnailUrl ? (
              <Box
                component="img"
                src={msg.thumbnailUrl}
                alt="PDF thumbnail"
                sx={{
                  width: 100,
                  height: "auto",
                  borderRadius: 2,
                }}
              />
            ) : (
              <Typography sx={{ color: "#475569" }}>{msg.text}</Typography>
            )}
          </Box>
        );
      }

      return (
        <Box
          key={index}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
          }}
          className="flex justify-end gap-3"
        >
          <Avatar
            sx={{ bgcolor: "#304FFE", width: 28, height: 28, fontSize: 15 }}
          >
            K
          </Avatar>
          {msg.thumbnailUrl ? (
            <Box
              component="img"
              src={msg.thumbnailUrl}
              alt="PDF thumbnail"
              sx={{
                width: 100,
                height: "auto",
                borderRadius: 2,
              }}
            />
          ) : (
            <div className="text-slate-700 mt-0.5 text-md font-medium">
              {msg.text}
            </div>
          )}
        </Box>
      );
    };

    const renderInputTopBar = () => {
      return (
        <>
          {(thumbnailUrl || suggestionText) && (
            <Box
              display="flex"
              alignItems="center"
              position="relative"
              marginBottom={1}
              width="auto"
            >
              {thumbnailUrl && (
                <Box
                  display="flex"
                  alignItems="center"
                  position="relative"
                  marginBottom={1}
                  width={80}
                  height="auto"
                >
                  <Box
                    component="img"
                    src={thumbnailUrl}
                    alt="PDF thumbnail preview"
                    sx={{
                      width: 80,
                      height: "auto",
                      borderRadius: 1,
                      marginRight: 1,
                    }}
                  />
                  <IconButton
                    size="small"
                    onClick={removeThumbnail}
                    sx={{
                      position: "absolute",
                      top: -10,
                      right: -10,
                      color: "rgba(0, 0, 0, 0.6)",
                      bgcolor: "white",
                      "&:hover": {
                        bgcolor: "rgba(255, 255, 255, 0.8)",
                      },
                      zIndex: 1,
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              )}
              {suggestionText && (
                <Paper
                  elevation={1}
                  sx={{
                    padding: 1,
                    borderRadius: 2,
                    marginLeft: 2,
                    marginBottom: 1,
                    backgroundColor: "#e0e0e0",
                  }}
                >
                  {suggestionText}
                  <IconButton
                    size="small"
                    onClick={removeSuggestionText}
                    sx={{
                      //position: "absolute",
                      top: -20,
                      right: -20,
                      color: "rgba(0, 0, 0, 0.6)",
                      bgcolor: "white",
                      "&:hover": {
                        bgcolor: "rgba(255, 255, 255, 0.8)",
                      },
                      zIndex: 1,
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Paper>
              )}
            </Box>
          )}
        </>
      );
    };

    const renderInput = () => {
      if (isInSideBar) {
        return (
          <div className="bg-slate-50 mh-[15%] pt-2 p-2 flex flex-col justify-between">
            {renderInputTopBar()}
            <div className="flex flex-1">
              <IconButton component="label" className="self-start">
                <AttachFileIcon />
                <input
                  type="file"
                  accept="application/pdf"
                  hidden
                  onChange={handleFileUpload}
                />
              </IconButton>
              <div className="h-6 mt-2 border-l-[1px] p-2 border-slate-100" />
              <textarea
                placeholder="Write here..."
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                className="bg-transparent mt-2 outline-none text-md font-medium resize-none"
              />
            </div>
            <div className="flex justify-end">
              <IconButton
                onClick={handleSubmit}
                disabled={isSendDisabled}
                sx={{
                  borderRadius: "50%",
                  width: 40,
                  height: 40,
                }}
              >
                <SendIcon className="text-slate-700" />
              </IconButton>
            </div>
          </div>
        );
      }
      return (
        <Box
          borderRadius={suggestionText || thumbnailUrl ? 2 : 50}
          boxShadow={suggestionText || thumbnailUrl ? 4 : 0}
          p={2}
          mb={2}
        >
          {renderInputTopBar()}
          <Box
            display="flex"
            alignItems="center"
            //borderRadius={50}
            bgcolor="#f0f0f0"
            width="100%"
            borderRadius={suggestionText || thumbnailUrl ? 1 : 50}
            boxShadow={suggestionText || thumbnailUrl ? 0 : 2}
            p={suggestionText || thumbnailUrl ? 1 : 0}
            //boxShadow={2}
            sx={{ position: "relative", bottom: 0, bgcolor: "#FFF" }}
          >
            <Autocomplete
              options={suggestionOptions}
              freeSolo
              fullWidth
              onChange={handleAutocompleteChange}
              value={userInput}
              onInputChange={(event, newInput) => setUserInput(newInput)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Type a message"
                  value={userInput}
                  inputRef={textFieldRef}
                  onChange={(e) => setUserInput(e.target.value)}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton component="label">
                          <AttachFileIcon />
                          <input
                            type="file"
                            accept="application/pdf"
                            hidden
                            onChange={handleFileUpload}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleSubmit}
                          disabled={isSendDisabled}
                          sx={{
                            borderRadius: "50%",
                            width: 40,
                            height: 40,
                          }}
                        >
                          <SendIcon className="text-slate-700" />
                        </IconButton>
                      </InputAdornment>
                    ),
                    sx: {
                      borderRadius:
                        suggestionText || thumbnailUrl ? "none" : 50,
                      bgcolor: "#ffffff",
                      "& fieldset": {
                        border:
                          suggestionText || thumbnailUrl ? "none" : "none",
                      },
                    },
                  }}
                />
              )}
            />
          </Box>
        </Box>
      );
    };

    return (
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        className="font-sans"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {dragOver && (
          <Backdrop
            open={dragOver}
            sx={{
              zIndex: 1000,
              color: "#fff",
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            onDragLeave={handleDragLeave}
          >
            <CloudUploadIcon sx={{ fontSize: 100, mb: 2 }} />
            <Typography variant="h6">Drop file here</Typography>
          </Backdrop>
        )}
        <Box
          flexGrow={1}
          className="flex flex-col gap-6"
          sx={{ padding: 2, overflowY: "auto", marginBottom: 1 }}
        >
          {messages.map((msg, index) => renderMessage(msg, index))}
        </Box>
        {renderInput()}
        {/* Suggestion Buttons Below TextField */}
        {!isInSideBar && showSuggestions && isFirstRun && (
          <Box
            display="flex"
            justifyContent="space-between"
            mt={4}
            className="text-slate-700 bg-white"
          >
            <Button
              variant="contained"
              color="primary"
              className="border-slate-200"
              onClick={() => handleSuggestionClick(1)}
              startIcon={<LightbulbIcon />}
              sx={{
                borderRadius: 20,
                textTransform: "none",
                borderColor: "e2e8f0",
                bgcolor: "#FFF",
                color: grey[800],
                "&:hover": { bgcolor: grey[200] },
              }}
            >
              Digitise brand guidelines
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="border-slate-200"
              onClick={() => handleSuggestionClick(2)}
              startIcon={<HelpIcon />}
              sx={{
                borderRadius: 20,
                textTransform: "none",
                borderColor: "e2e8f0",
                bgcolor: "#FFF",
                color: grey[800],
                "&:hover": { bgcolor: grey[200] },
              }}
            >
              Create an animation
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="border-slate-200"
              onClick={() => handleSuggestionClick(3)}
              startIcon={<AutoAwesomeIcon />}
              sx={{
                borderRadius: 20,
                textTransform: "none",
                borderColor: "e2e8f0",
                bgcolor: "#FFF",
                color: grey[800],
                "&:hover": { bgcolor: grey[200] },
              }}
            >
              Get inspirations
            </Button>
          </Box>
        )}
      </Box>
    );
  }
);
