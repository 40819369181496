export const extractTokenFromUrl = (): string | null => {
  const hash = window.location.hash;
  const tokenMatch = hash.match(/access_token=([^&]*)/);
  return tokenMatch ? tokenMatch[1] : null;
};

export const storeToken = (token: string) => {
  sessionStorage.setItem('access_token', token); // Use sessionStorage
};

export const getStoredToken = (): string | null => {
  return sessionStorage.getItem('access_token'); // Use sessionStorage
};

export const isTokenExpired = (token: string): boolean => {
  try {
    const payload = JSON.parse(atob(token.split(".")[1])); // Decode JWT payload
    const expiration = payload.exp * 1000; // Convert to milliseconds
    console.log("Date.now: ", Date.now());
    console.log("Expiration: ", expiration);
    return Date.now() > expiration;
  } catch (error) {
    console.error("Invalid token:", error);
    return true; // Treat as expired if there’s an error
  }
};

export const initializeToken = (): string | null => {
  const token = extractTokenFromUrl();
  if (token) {
    storeToken(token); // Store token in sessionStorage
    console.log('Token extracted and stored:', token);
  }
  return token;
};

export const clearToken = () => {
  sessionStorage.removeItem('access_token'); // Use sessionStorage
};
