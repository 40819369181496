import React, { createContext, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Agent from "./components/Agent";
import Chat from "./components/Chat";
import ProtectedRoute from "./components/ProtectedRoute";
import "./index.css";

export interface Message {
  text: string;
  sender: "bot" | "user";
  file?: File;
  thumbnailUrl?: string;
}

interface ChatContextType {
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
}

export const ChatContext = createContext<ChatContextType>({
  messages: [],
  setMessages: () => {},
});

const App: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* Protect the /agent and /chat routes */}
        <Route
          path="/agent"
          element={
            <ProtectedRoute>
              <ChatContext.Provider
                value={{ messages, setMessages }}
              >
                <Agent />
              </ChatContext.Provider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/chat"
          element={
            <ProtectedRoute>
              <Chat />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
