// src/components/Sidebar.tsx
import React from "react";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ChatBox, ChatBoxRef } from "./ChatBox"; // Import ChatBoxRef

export interface SidebarProps {
  chatBoxRef: React.RefObject<ChatBoxRef>;
  onClose: () => void; // Add the onClose prop
  setPDFData: (PDFDetails: PdfData) => void;
  firstRunComplete: () => void;
}
interface LinkContent {
  name: string;
  url: string;
}

interface ColorContent {
  name: string;
  type: string;
  hexcode: string;
  rgb: string;
}

interface GenericContent {
  name: string;
  type: string;
}
type PdfData = {
  type: string;
  contents: LinkContent[] | ColorContent[] | GenericContent[];
}[];

export const Sidebar: React.FC<SidebarProps> = ({
  chatBoxRef,
  onClose,
  setPDFData,
  firstRunComplete,
}) => {
  return (
    <Box
      width="30%"
      display="flex"
      flexDirection="column"
      position="relative"
      className="bg-background m-4 rounded-xl rounded-tr-none border-2 border-slate-100"
    >
      <IconButton
        onClick={onClose} // Call the onClose function when clicked
        sx={{
          backgroundColor: "#94A3B8",
          borderTopRightRadius: 0,
          alignSelf: "flex-end",
          marginBottom: 1,
          margin: 1,
        }}
      >
        <CloseIcon className="text-slate-50" />
      </IconButton>
      {/* ChatBox with addMessage function */}
      <ChatBox
        ref={chatBoxRef}
        // uploadPdf={uploadPdf}
        setPDFData={setPDFData}
        isInSideBar
        firstRunComplete={firstRunComplete}
      />
    </Box>
  );
};
